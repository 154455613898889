/* Ajustes gerais */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
    overflow-x: hidden; /* Impede rolagem horizontal */
}

/* Conteúdo principal */
.container {
    padding: 20px;
    max-width: 100%; /* Garante que o conteúdo ocupe a largura total */
}

.content {
    padding: 20px;
    max-width: 100%;
}

/* Estilo da Navbar */
.custom-navbar {
    padding-top: 5px; /* Diminui o espaçamento superior */
    padding-bottom: 5px; /* Diminui o espaçamento inferior */
    margin-bottom: 0; /* Remove qualquer margem inferior */
}

.custom-navbar .navbar-brand,
.custom-navbar .nav-link {
    line-height: 1.2; /* Ajusta a altura da linha para os itens da navbar */
    padding-top: 5px; /* Reduz o espaçamento superior */
    padding-bottom: 5px; /* Reduz o espaçamento inferior */
    font-size: 14px; /* Reduz o tamanho da fonte se necessário */
}

header {
    background-color: #0056b3;
    color: white;
    margin: 0;
    padding: 10px 0;
    text-align: center;
    width: 100%;
}

header h1 {
    margin-bottom: 0.5em;
}

header p {
    margin: 0;
    line-height: 1.2;
    padding: 2px 0;
}

.specialty, .credentials {
    margin: 0;
    padding: 0;
}

section {
    margin-bottom: 20px;
}

#areas-de-atuacao p {
    column-count: 2;
    column-gap: 50px; /* Ajustado para melhorar o espaçamento */
    margin: 0;
}

#areas-de-atuacao p::after {
    content: "";
    display: table;
    clear: both;
}

h1, h2, h3 {
    margin: 0;
}

h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

#contato p {
    margin-bottom: 20px;
}

footer {
    padding: 10px 20px;
    text-align: center;
}

.instagram {
    text-align: center;
    margin-bottom: 10px;
}

.instagram img {
    margin-left: 10px;
    vertical-align: middle;
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
    .container {
        width: 100%;
    }

    .content {
        padding: 20px;
        width: 100%;
        max-width: 100%;
    }

    header {
        width: 100%;
    }

    body {
        background-color: white !important;
    }
}
